<script>
import Loader from '@/components/Loader';
import getIdCheck from '@/api/hooyu/getIdCheck';
import getPerson from '@/api/persons/getPerson';

export default {
  name: 'EmbeddedHooYu',
  components: { Loader },
  data() {
    return {
      contentUrl: '',
      name: '',
      height: '600px',
      loading: true,
    };
  },
  props: ['entityType', 'entityId', 'clientId'],
  created() {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    window[eventMethod](
      messageEvent,
      (e) => {
        if (e?.data?.height) {
          this.height = e.data.height;
        }
      },
      false,
    );
  },
  async mounted() {
    this.contentUrl = (await getIdCheck(this.entityType, this.entityId, this.clientId)).link;
    const { first_name, middle_name, last_name, title } = (await getPerson(this.clientId)).data;
    this.name = [title, first_name, middle_name, last_name].filter((v) => !!v).join(' ');
    this.loading = false;
  },
  watch: {
    height(newHeight) {
      console.log(newHeight);
    },
  },
  methods: {
    navigateToCaseView() {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
  },
};
</script>

<template>
  <div class="card card-body p-0 pb-2 mb-5">
    <div slot="form-content" class="home">
      <loader v-if="loading">Loading...</loader>
      <div v-else class="row">
        <div class="col">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h4 class="card-title"><b>Identity Verification:</b> {{ this.name }}</h4>
            </div>
            <div class="col-md-12 p-0 mb-2">
              <iframe
                :height="height"
                :src="contentUrl"
                allow="geolocation; camera"
                allowfullscreen
                frameborder="0"
                style="border: none"
                width="100%"
              ></iframe>
            </div>
            <div class="col-md-12 pb-2">
              <button class="btn btn-info border-info float-right" @click="navigateToCaseView">Return to Case</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
